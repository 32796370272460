import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const Col = styled.div`
  width: 33.333333333333336%;
  margin-left: 50px;
  &:first-child {
    margin-left: 0px;
  }

  @media (max-width: 1024px) {
    margin-left: 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
`;

export const Card = styled.div`
  margin-top: 66px;
  background-color: #fff;
  min-height: 100px;
  border-radius: 5px;
  box-shadow: 0px 6px 16px #00000029;
  &.best-value {
    margin-top: 0px;
  }

  @media (max-width: 767px) {
    margin-top: 20px;

    &.best-value {
      margin-top: 20px;
    }
  }
`;

export const BestValue = styled.div`
  text-align: center;
  padding: 10px;
  background-color: #e26447;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  text-transform: uppercase;
  border-bottom: 2px solid #fff;
`;

export const CardBody = styled.div`
  background-image: url(/images/sales-letter/arrowgrey.svg);
  background-repeat: no-repeat;
  min-height: 200px;
  background-size: contain;
  padding: 10px 25px;
  &.best-value {
    background-image: url(/images/sales-letter/arrowblue.svg) !important;
    border-radius: 0;
  }
  @media (max-width: 500px) {
    background-position-y: -10px;
    border-radius: 5px 5px 0 0;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 35px;
  letter-spacing: -0.5px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: 1024px) {
    font-size: 30px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 500px) {
    margin-top: 10px;
    font-size: 32px;
  }

  @media (max-width: 375px) {
    margin-top: 5px;
  }

  @media (max-width: 320px) {
    font-size: 26px;
    margin-top: 0;
  }
`;

export const Description = styled.div`
  color: #fff;
  text-align: center;
  font-size: 12px;
`;

export const Logos = styled.img`
  display: block;
  margin: 0 auto;
  padding-bottom: 10px;
  width: 100%;
  margin-top: 10px;
`;

export const Button = styled.button`
  display: block;
  margin: 5px auto;
  width: 100%;
  background: transparent linear-gradient(180deg, #f4dea2 0%, #ecc251 100%) 0%
    0% no-repeat padding-box;
  border: 2px solid #997f33;
  border-radius: 5px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #1d1d1d;
  text-transform: uppercase;
  padding: 15px;
  cursor: pointer;

  &:hover {
    border: 2px solid #000;
  }
  & span {
    position: relative;
    letter-spacing: -0.5px;
    margin-right: 43px;

    @media (max-width: 959px) {
      width: 25px;
      margin-right: 33px;
    }

    &:after {
      content: '';
      background-image: url(/images/shopping-cart.svg);
      width: 33px;
      height: 29px;
      position: absolute;
      background-repeat: no-repeat;
      margin-left: 10px;
      background-size: contain;

      @media (max-width: 959px) {
        width: 25px;
      }

      @media (max-width: 856px) {
        width: 20px;
      }

      @media (max-width: 767px) {
        width: 33px;
      }

      @media (max-width: 320px) {
        width: 28px;
      }
    }
  }
  &.processing {
    padding: 21px;
    &:before {
      content: '';
      height: 22px;
      width: 100%;
      background-image: url('/images/three-dots.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    & > span {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    font-size: 25px;
    padding: 15px 10px;
  }

  @media (max-width: 959px) {
    font-size: 20px;
  }

  @media (max-width: 856px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 30px;
  }

  @media (max-width: 320px) {
    font-size: 22px;
  }
`;

export const Image = styled.div`
  margin: 0 auto;
  display: block;
  text-align: center;
  padding-top: 40px;
  & img {
    max-width: 100%;
    height: 200px;

    @media (max-width: 1024px) {
      height: 135px;
    }
  }
`;
export const Price = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #213f4e;
  & span {
    font-size: 75%;
  }
`;

export const Savings = styled.div`
  text-align: center;
  letter-spacing: -0.24px;
  color: #e26447;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  & span {
    font-size: 75%;
  }
`;

export const FreeShipping = styled.div`
  letter-spacing: 0px;
  color: #213f4e;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  padding-top: 3px;
  margin-top: 3px;
  border-top: 1px solid #bfc8cd;
  text-align: center;
  position: relative;
  margin-left: 20px;
  & > span:before {
    content: '';
    height: 21px;
    width: 100%;
    background-image: url('/images/check-circle.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    margin-left: -30px;
    margin-top: 2px;
  }

  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;
