import React, { useContext } from 'react';
import * as S from './ProductSelectorV3.styles';
import { Image } from '@builder.io/react';
import { addToCart } from '../../utils/addToCart';
import FunnelContext from '../../context/FunnelContext';

const BestValue = ({ best_value }) => {
  if (best_value) {
    return <S.BestValue>Best Value</S.BestValue>;
  } else {
    return '';
  }
};

const FreeShipping = ({ free_shipping }) => {
  if (free_shipping) {
    return (
      <S.FreeShipping>
        <span>Free US Shipping</span>
      </S.FreeShipping>
    );
  } else {
    return '';
  }
};

const ProductSelector = props => {
  const { boxes, products_list } = props;

  const context = useContext(FunnelContext);

  const {
    currency,
    buttonClicked,
    setButtonClicked,
    setSubmittedToCart
  } = context;

  const stopLoading = () => {
    setButtonClicked(false);
    setSubmittedToCart(false);
  };

  const currencySymbol = currency?.symbol || '$';
  const currencyCode = currency?.code || 'USD';

  const extraData = {
    ...JSON.parse(context.extra),
    currency_code: currencyCode,
    currency_symbol: currencySymbol,
    setButtonDisabled: () => stopLoading(),
    setButtonClicked: () => stopLoading(),
    setSubmittedToCart: () => stopLoading()
  };

  const triggerAddToCart = index => {
    try {
      setButtonClicked(true);
      setSubmittedToCart(true);

      const { discounts, products } = products_list;
      const product = products[index];

      return addToCart(
        product,
        discounts,
        extraData,
        {},
        context.path,
        context.funnelUrl
      );
    } catch (err) {
      console.log(
        'the `products_list` is not bind to a state variable. Go to Data on Builder.io!'
      );
      setButtonClicked(false);
      setSubmittedToCart(false);
    }
  };

  const getRegularPrice = index => {
    const { products } = products_list;
    try {
      return products[index]?.checkoutData?.price;
    } catch (err) {
      return 0;
    }
  };

  const getDiscountedPrice = index => {
    const { products } = products_list;
    try {
      return products[index]?.checkoutData?.discounted_price;
    } catch (err) {
      return 0;
    }
  };

  return (
    <S.Container>
      {boxes.map(
        (
          { title, description, best_value, button_text, image, free_shipping },
          index
        ) => {
          const regular_price = getRegularPrice(index);
          //const regular_price_arr = String(regular_price.toFixed(2)).split('.');

          const discounted_price = getDiscountedPrice(index);
          const discounted_price_arr = String(
            discounted_price.toFixed(2)
          ).split('.');

          const savings = Number(regular_price - discounted_price);
          const savings_arr = String(savings.toFixed(2)).split('.');

          return (
            <S.Col key={index}>
              <S.Card className={best_value ? 'best-value' : ''}>
                <BestValue best_value={best_value} />
                <S.CardBody className={best_value ? 'best-value' : ''}>
                  <S.Title>{title}</S.Title>
                  <S.Description>{description}</S.Description>
                  <S.Image>
                    <Image
                      image={image ?? '/images/product-image.png'}
                      alt={title}
                    />
                  </S.Image>
                  <S.Price>
                    {currencySymbol}
                    {discounted_price_arr[0] ?? 0}
                    <span>.{discounted_price_arr[1] ?? '00'}</span>
                  </S.Price>
                  <S.Savings>
                    You save {currencySymbol}
                    {savings_arr[0] ?? 0}
                    <span>.{savings_arr[1] ?? '00'}</span>
                  </S.Savings>
                  <FreeShipping free_shipping={free_shipping} />
                  <S.Button
                    onClick={() => triggerAddToCart(index)}
                    disabled={buttonClicked ? 'disabled' : ''}
                    className={buttonClicked ? 'processing' : ''}
                  >
                    <span>
                      {button_text && button_text !== ''
                        ? button_text
                        : 'Add To Cart'}
                    </span>
                  </S.Button>
                  <S.Logos src="/images/sales-letter/logos.svg"></S.Logos>
                </S.CardBody>
              </S.Card>
            </S.Col>
          );
        }
      )}
    </S.Container>
  );
};

export default ProductSelector;
